// === Responsive design

$bp4: calc(400em / 16);
$bp5: calc(500em / 16);
$bp6: calc(600em / 16);
$bp7: calc(700em / 16);
$bp8: calc(800em / 16);
$bp9: calc(900em / 16);
$bp10: calc(1000em / 16);

@mixin respond($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

// === Animation

@mixin animation(
  $name,
  $duration,
  $timing-function,
  $delay: 0,
  $iteration-count: 1,
  $direction: forward,
  $fillmode: fowards
) {
  -webkit-animation-name: $name;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $timing-function;
  -webkit-animation-delay: $delay;
  -webkit-animation-iteration-count: $iteration-count;
  -webkit-animation-direction: $direction;
  -webkit-animation-fill-mode: $fillmode;

  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing-function;
  animation-delay: $delay;
  animation-iteration-count: $iteration-count;
  animation-direction: $direction;
  animation-fill-mode: $fillmode;
}

// === Basic styles

@mixin basicBtnStyle() {
  padding: 0;
  margin: 0;
  display: inline-block;
  color: var(--dark);
  background-color: transparent;
  border: none;
  outline: none;
  font-family: var(--basic-fonts);
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.6;
  cursor: pointer;
}

@mixin basicTextareaStyle() {
  font-family: var(--basic-fonts);
  color: var(--dark);
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.4;
  padding: 1rem 2rem;
  width: 100%;
  min-height: 10.96rem; //4 rows
  resize: vertical;
  box-sizing: border-box;
  overflow: hidden; //hide scrollbar
  outline: none;
  border: none;
  font-weight: 300;

  &::placeholder {
    font-size: 1.6rem;
    color: var(--grey-2);
    font-family: var(--basic-fonts);
    font-weight: 300;
  }
}

@mixin basicInputStyle() {
  font-family: var(--basic-fonts);
  color: var(--dark);
  font-weight: 300;
  font-size: 1.6rem;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
  outline: none;

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder {
    font-size: 1.6rem;
    color: var(--grey-2);
    font-family: var(--basic-fonts);
    font-weight: 300;
  }
}
