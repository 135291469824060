.footer {
  background-color: var(--grey);
  padding: 2rem 1rem;
  box-sizing: border-box;
  font-size: 1.5rem;

  &--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 70rem;
    margin: 0 auto;
  }
  &__logo {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1.6rem;
    svg {
      width: 4.4rem;
      margin-bottom: 0.8rem;
    }
    span {
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary);
      width: max-content;
    }
  }
  &__contents {
    flex: 1 1 fit-content;
    padding: 0.4rem 0;
    & > span {
      display: inline-block;
      margin-right: 1.4rem;
      & > span {
        display: inline-block;
      }
    }
    &__title {
      color: var(--primary-2);
      font-weight: 500;
      font-size: 1.3rem;
    }
    svg {
      margin-right: 0.2rem;
      transform: translateY(1px);
      path,
      polyline,
      circle,
      rect {
        stroke: var(--primary-2);
      }
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid var(--light);
    margin: 1rem 0;
  }

  &--bottom {
    font-weight: 400;
    text-align: center;
    color: var(--grey-3);

    a {
      color: var(--grey-3);
    }
    span {
      display: inline-block;
    }
    & > span:not(:last-child) {
      margin-right: 1.6rem;
      &:after {
        content: '';
        width: 2px;
        height: 2rem;
        background-color: red;
        color: red;
      }
    }
    &__policy {
      font-weight: 500;
    }
  }
}
