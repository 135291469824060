// === Animations

@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }
  @keyframes #{$animation_name} {
    @content;
  }
}

@include keyframe(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include keyframe(fadeOut) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@include keyframe(fadeInOut) {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@include keyframe(slideToBottom) {
  0% {
    opacity: 0;
    transform: translate(0, -10%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
